import React, { useEffect, useState, useContext } from "react";
import { Moneyformat, Cardstructure, post, sendTrack } from "../Generals";
import Loader from './../Components/Loader'

import GeneralContext from "../utils/generalContext";
import {Row, Col} from "react-bootstrap";
import { useParams } from "react-router";

export const PayOrder = () => {
    const [content,setContent]=useState(null)
    const { global, user } = useContext(GeneralContext)
    const userid = user.user.user.id_alumno
    const { id } = useParams();
    const itemid = id
    useEffect(() => {
        if(content){
            window.location.href = `${global.domain}payments/${content.payment.action}/${itemid}`;
        }
    }, [content])

    useEffect(()=>{
        post(
            global.domain+"v3-api/v3/order_data.json",
            { itemid, userid },
            setContent
        )
        sendTrack(userid, window.location.pathname, window.location.hostname, global)
    },[])

    return <> {content === null
        ?
            <Loader/>
        :
        <Row className={'justify-content-center my-5'}>
            <Col lg={6} md={8} sm={12}>
                <Cardstructure title={'Orden #'+content.order.id}>
                    <h4><Moneyformat currency={content.currency} value={content.order.total}/></h4>
                    <p>Estamos procesando su orden..</p>
                    <Loader/>

                </Cardstructure>
            </Col>
        </Row>
        }
    </>

}

