import React, { useContext, useState, useEffect } from "react";
import { useLocation, redirect } from 'react-router' 

import { useAlert, post } from "../../Generals";
import ImageProfile from "../../Components/User/ImageProfile";
import Avatar from "../../Components/User/Avatar";
import SideMenu from "./sidemenu"
import noimg from "../../no-img.jpg";
import logo from "../../logo.png";
import Loader from "../../Components/Loader";
import moment from "moment";
import { Alert, Dropdown, Modal } from "react-bootstrap";
import { CreateOutline, FileTrayFullOutline, HomeOutline, MenuOutline, LogoWhatsapp, InformationCircleOutline } from "react-ionicons";
import GeneralContext, { VerifyContractContextProvider } from '../../utils/generalContext'

let styles = {
    iconsm:{
        size:"15px"
    },
    iconmd:{
        size:"30px"
    },
    iconlg:{
        size:"50px"
    },
}


class Contract {
    constructor(contract){
        this.today = moment().unix();
        this.limmitDay = moment().add(5, 'days').unix();
        this.contract = contract;
        this.proximoVencimiento = moment(contract.Proxvencimiento).unix();
        this.promesaPago = moment(contract.Proxpromesadepago).unix();
        this.proximoVencimientoExtension = moment(contract.Proxvencimiento).add(4, 'days').unix();
        this.saldoactual = contract.Saldoactual;
    }

    contratoProximoVencer(){
        return this.saldoactual > 0 && ( this.proximoVencimiento < this.today ) 
        && (
                (
                    (this.promesaPago < this.today) && 
                    (this.proximoVencimiento < this.limmitDay && this.promesaPago < this.limmitDay)
                )
                || 
                (
                    !this.promesaPago && this.proximoVencimiento < this.limmitDay && this.promesaPago < this.limmitDay
                )
            )
    }

    contratoVencido(){
        return this.saldoactual > 0 && (this.proximoVencimiento < this.today) 
        && (
            ( (this.promesaPago < this.today) && (this.proximoVencimientoExtension < this.today) )
            || 
            ( !this.promesaPago && this.proximoVencimiento < this.limmitDay )
           )
    }

    get notified(){
        return localStorage.getItem('notified');
    }

    set notified(value){
        localStorage.setItem('notified', value);
    }
}

const Layout = ( props ) => {
    const [showSidebar,setShowSidebar]=useState(true)
    const [statusd, setStatusd] = useState(null)
    const [alert, setAlert] = useAlert();
    const {modal, main, user, contract, oficina, global, loadingContent} = useContext(GeneralContext);

    const location = useLocation();
    const vistaActual = location.pathname.replace("/","");
    let verifyContractObject

    if(contract){
        verifyContractObject = new Contract(contract);
        const vistaPermitidas = ['solicitud-de-pago', 'pagar-orden', 'comprar', 'preguntas-frecuentes'];
        const redirectlink = '/solicitud-de-pago';
        if(verifyContractObject.contratoProximoVencer() && !verifyContractObject.notified && !vistaPermitidas.includes(window.location.pathname)){
            verifyContractObject.notified = true;
            window.location.href = redirectlink;
        }
        if(verifyContractObject.contratoVencido() && !vistaPermitidas.includes(vistaActual)){
            window.location.href = redirectlink;
        }
    }

    const destroySession = () => {
        user.setUser(null)
        localStorage.removeItem('user')
        localStorage.removeItem('notified')
        window.user = {};
    }

    const checkStatus = () =>{
        post(
            global.domain+"v3-api/v3/check_status.json",
            { 
                userid: user.user.user.id_alumno
            },
            setStatusd
        )
    }

    useEffect(() => {
        const interval=setInterval(()=>{
            checkStatus()
        }, 5000)
        return ()=>clearInterval(interval)
    },[])
    return <VerifyContractContextProvider value={verifyContractObject || null}>
        <div className={showSidebar ? null : "toggle-sidebar"}>
        <div className={'sidebar'}>
            <div className={'sidebar-header'}>
                <img src={logo} alt={main.main.name}/>
            </div>
            <div className={'sidebar-body'}>
                <SideMenu />
            </div>
        </div>
        <div className={'content content-page'}>
            {!loadingContent.loadingContent ?
                <div>
                    <Modal
                        show={modal.modalShow}
                        onHide={modal.toggleModal}
                        {...props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {modal.modalContent.title}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            { typeof modal.modalContent.content === 'object' ?
                                modal.modalContent.content
                                : 
                                <div dangerouslySetInnerHTML={{__html : modal.modalContent.content}} ></div>
                            }
                        </Modal.Body>
                    </Modal>

                    <div className={'header'}>
                        <div className={'header-left'}>
                            <MenuOutline onClick={()=>setShowSidebar(!showSidebar)} 
                            color={'#00000'} height={styles.iconmd.size} width={styles.iconmd.size} />
                        </div>
                        <div>
                            { statusd?.alerts.length > 0 && <div className="alert-primary alert"> 
                                {statusd?.alerts.map((alert, idx) => <div key={idx} className="d-flex"><span><InformationCircleOutline color={'#00000'} height="20px" /></span><span dangerouslySetInnerHTML={{__html: alert.mensaje}}></span></div>)}
                            </div>
                            }
                        </div>
                        <div className={'header-right'}>
                            <Dropdown className={'dropdown-loggeduser'}>
                                <Dropdown.Toggle drop={'start'} variant="light" id="dropdown-basic">
                                    { user?.user?.user?.Nombre || null }
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <div className="media align-items-center d-flex py-3">
                                            <ImageProfile />
                                            <div className="media-body" style={{marginLeft:15}}>
                                                <h5 className={'my-0'}>{ user?.user?.user?.Nombre || null }</h5>
                                            </div>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item href={'/modificar-perfil'}><CreateOutline color={'#ccc'} /> Modificar perfil</Dropdown.Item>
                                    <Dropdown.Item href={'/notificaciones'}><FileTrayFullOutline color={'#ccc'} /> Notificaciones</Dropdown.Item>
                                    <Dropdown.Item onClick={()=>destroySession()}><FileTrayFullOutline color={'#ccc'} /> Salir</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <span>
                            </span>
                        </div>
                    </div>
                    <div className={'content-header justify-content-between border-bottom'}>
                        <a className={'link'}
                                href={'/escritorio'}>
                            <HomeOutline
                                color={'#00000'}
                                height={styles.iconsm.size}
                                width={styles.iconsm.size}
                            />
                        </a>
                            { statusd?.asesoria?.status === 0 &&
                            <>
                                { statusd?.sesiones > 0 && statusd?.profesoresenlinea?.length > 0 &&
                                    <Alert className={'my-0 py-1 mr-2'} variant={'info'}>Hay profesores disponibles para consultar en línea. <a href={'/escoger-consulta'} className="link">Solicita asesoría</a></Alert> 
                                }
                                { statusd?.sesiones <= 0 &&
                                    <Alert className={'my-0 py-1 mr-2'} variant={'warning'}>No tiene sesiones disponibles para consultas. <a href={"/comprar/1"} className="alert-link">Comprar sesiones</a></Alert>
                                }
                            </>
                            }
                            {/* 1 or 2 means a session exists. 1 is ready */}
                            { [1,2].includes(statusd?.asesoria?.status) &&
                                <Alert variant={statusd?.asesoria?.status === 1 ? "success" : "danger"} className={'py-1 my-0 mr-2'}>{statusd?.asesoria?.statusmsg} <a className={'link '} href={`/consulta/${statusd?.asesoria?.cursoid}/${statusd?.asesoria?.itemid}` }>
                                    Entrar a la sala de espera.
                                </a>
                                </Alert>
                            }
                            <div className={'d-flex'}>
                                <div>
                                    {
                                        statusd?.profesoresenlinea.map(prof => {
                                        let profesor = prof.cursos_profesore
                                        return  <Avatar src={ profesor.img ? global.domain + profesor.img : noimg} alt={profesor.nombre} />
                                    })
                                    }
                                    {statusd?.profesoresenlinea.length == 0 && <div className={'alert alert-info'}>No hay profesores disponibles.</div>}
                                </div>
                            </div>
                    </div>
                    <div className={'content-body'}>
                        {alert?.msg?.alert && <Alert bg={alert.type}
                                        onClose={() => setAlert(false)}
                                        dismissible>{alert.msg.alert}</Alert>}
                        {props.children}
                    </div>
                </div>
                :
                <Loader/>
            }
        </div>
        {oficina?.wh_qr && (
            <div className="btn-whatsapp position-fixed d-flex">
                <a 
                    title={oficina.INSTITUTO}
                    href={`https://api.whatsapp.com/send?phone=521${oficina.wh_atencion}&text=Solicito ayuda`}>
                    <img 
                        src={global.domain+oficina.wh_qr}
                        title={oficina.INSTITUTO}
                        alt={oficina.INSTITUTO}/>
                </a>
            </div>
        )}
        {!oficina?.wh_qr && oficina?.wh_atencion &&
            <a 
                className="btn-whatsapp position-fixed btn-info btn d-flex justify-content-center align-content-center" 
                target="_blank" 
                href={`https://api.whatsapp.com/send?phone=521${oficina.wh_atencion}&text=Solicito ayuda`}>
                <LogoWhatsapp color={'#fff'} />  Asistencia técnica
            </a>
        }
    </div>
    </VerifyContractContextProvider>
}

 export default Layout;
