
import React, { useContext, useState } from "react";
import Loader from './../Components/Loader'

import SidePanelHome from "../Components/Shared/SidePanelHome";
import GeneralContext from "../utils/generalContext";
import axios from "axios";

export const Login = () => {
    const { global, user } = useContext(GeneralContext);
    const [ password, setPassword ] = useState('');
    const [ username, setUsername ] = useState('');
    const [ error, setError ] = useState(null);
    const [ loader, setLoader ] = useState(false);

    const logginIn = async() => {
        setError(null)
        setLoader(true)
        let url=global.domain+"v3-api/v3/login.json";
        let res = await axios.post(url, {
            username: username,
            password: password
          }).catch(function (error) {
            setError(error.message)
            console.error(error.message)
            setLoader(false)
          });

          if(!res.data){
            setPassword('')
            setError('Error con el usuario y contrasena')
            setLoader(false)
            return;
          }
          localStorage.setItem('user',JSON.stringify(res.data))
          user.setUser(res.data);

        setLoader(false)
    }

    return  (
        <SidePanelHome>
            <h4 className="mt-5 signin-title">Bienvenid@!</h4>
            <h5 className="signin-subtitle">Favor identificarse para continuar.</h5>
                <div className="signin-form">
                    <div className="form-group">
                        {error && <div className={'alert alert-danger'}>{error}</div>}
                        <label>Matrícula</label>
                        <input 
                        type="text" 
                        className="form-control" 
                        onChange={
                            (e) => setUsername(e.target.value)
                        }
                        name="username" 
                        placeholder="Escriba su matrícula académica" 
                        value={username} 
                        />
                    </div>

                    <div className="form-group">
                        <label className="d-flex justify-content-between">
                            <span>Contraseña</span>
                            {/* <a href="/users/reset" className="tx-13">Olvidó contraseña?</a> */}
                        </label>
                        <input 
                            type="password" 
                            className="form-control" 
                            onChange={
                                (e) => setPassword(e.target.value)
                            }
                            name="password" 
                            placeholder="Escriba contraseña" 
                            value={password} 
                            />
                    </div>

                    <div className="form-group d-flex mg-b-0">
                            {loader ? 
                                <Loader />
                                :
                                <button
                                    onClick={
                                        ()=>logginIn()
                                    }
                                    className="btn btn-brand-01 btn-uppercase flex-fill">
                                        Identificarse
                                </button>
                            }
                            
                    </div>
                    <a className="mg-t-auto mg-b-0 tx-md tx-color-03 my-3" href='/forgot-password'>¿Olvidaste tu constraseña?</a>
                </div>
        </SidePanelHome>
    );
}

