import React, { useState, useEffect, useContext } from "react";

import { useAlert } from "./../../Generals";
import { useParams } from "react-router-dom";
import dateformat from 'dateformat'

import CampoDeTexto from "./Components/CampoDeTexto";
import FeedbackForm, { RenderFeedbackRate } from "../FeedbackForm";
import Loader from "../../Components/Loader";
import {
    fetching,
    Title,
    post,
    sendTrack
} from "../../Generals";
import DateFormatted from "../../Components/DateFormatted";
import GeneralContext from "../../utils/generalContext";

import {
    AttachOutline, CheckmarkCircle,
    CloudUploadOutline, CodeSlashOutline,
    DocumentTextOutline,
    ListOutline, PeopleCircleOutline,
    PlayCircleOutline, TimerOutline,
    VideocamOutline, Star
} from "react-ionicons";

const PaginationItems = props =>{
    let {submitAwnsers} = props
    let itemspage = []
    itemspage.push(<li key={'paginatesubmit'} className={'page-item'}>
        <a onClick={submitAwnsers} className={'btn btn-info text-white'}>Enviar</a>
    </li>)

    return  <div className={'paginationul'}>
        <ul className={'pagination'}>
            {itemspage}
        </ul>
    </div>
}


const Fieldvalue = props => {
    let {item, autoSave, data} = props
    let actualmodule = data.moduloactual
    const cursos_items_alumnos_questions = data.item_alumnos_records_questions.find(iaq => iaq.questionid == item.id && iaq.itemalumnoid == actualmodule.id);
    let savedid = 0
    let classbginput
    let pastquestions
    let pastvaluecorrect
    let teacherquestion
    let pastvalueteachercomment
    let pastvalue = null;
    if(cursos_items_alumnos_questions !== undefined){
        pastquestions = cursos_items_alumnos_questions
        if(pastquestions){
            if(item.campodetexto === "1"){
                if(pastquestions.cursos_items_alumnos_awnser[0] !== undefined){
                    classbginput="bg-light"
                    pastvalue=pastquestions.cursos_items_alumnos_awnser[0].valuetext
                }
            }else if(item.explicacion === "0"){
                let optionsselected=pastquestions.cursos_items_alumnos_awnser
                if(optionsselected !== undefined){
                    let getoptionsselected = optionsselected.filter(optionsel=>optionsel.value !== null)
                    let arr =[]
                    getoptionsselected.map(optionselg=>{
                        arr.push(optionselg.value)
                    })
                    pastvalue = arr
                }
            }
            if(pastquestions.profesorid !== null){
                pastvaluecorrect=pastquestions.correct
                teacherquestion=pastquestions.cursos_profesore.nombre
            }

            pastvalueteachercomment=pastquestions.teachercomment
            savedid=pastquestions.id
        }
    }

    if(item.campodetexto == "1"){
        //Si es campo de texto
        return <CampoDeTexto 
        props={
            {
                item,
                actualmodule,
                pastvaluecorrect,
                pastvalueteachercomment,
                teacherquestion,
                savedid,
                classbginput,
                pastvalue, 
                autoSave
            }
        }
        />
    }else if(item.explicacion == "1"){
        //Si es explicacion
        return null
    }else{
        //Si es checkbox
        return <div className={'col'}>
            {pastvalue !== false ? <div id={'fieldquestions'+item.id} data-savedid={savedid} data-type={2} data-savedid={savedid} data-id={item.id} className={'questionsfield fieldquestions'}>
            { actualmodule.corrected === true && <div className={'alert alert-light'}>
                { actualmodule.grade == "100" || pastvaluecorrect ? <span className="badge badge-success mr-2">Aprobado</span> : null}
                { actualmodule.grade !== "100" && !pastvaluecorrect &&<span className={'badge badge-danger mr-2'}>Incorrecto</span>}
                { teacherquestion && <span>{teacherquestion}</span>}
                { pastvalueteachercomment && <span className={'ml-2 border'}>{pastvalueteachercomment}</span>}
            </div> }
            {item.options !== undefined ?
                Object.values(item.options).map((itemop)=>{
                    let chekedvalue=false;
                    if(pastvalue !== null && pastvalue.length > 0){
                        if(pastvalue.includes(itemop.id)){
                            chekedvalue=true
                        }
                    }
                    return <div className={'d-inline mr-3'} key={'itemoptions'+itemop.id}><label>
                        <input type={'checkbox'} data-id={item.id}
                               readOnly={(actualmodule.grade == "100") || (pastvaluecorrect === true && actualmodule.corrected === true) ? true : false}
                               disabled={(actualmodule.grade == "100") || (pastvaluecorrect === true && actualmodule.corrected === true) ? true : false}
                               data-type={2}
                               data-itemalumnoid={actualmodule.id} data-savedid={savedid}
                               defaultChecked={chekedvalue}
                               value={itemop.id} className={'checkfield'+item.id}
                               onChange={pastvaluecorrect === true && actualmodule.corrected === true ? null : (event)=>autoSave(event)}
                               name={'field'+item.id}/> {itemop.awnser}</label></div>
                }) :null}
        </div> : <Loader/>}</div>
    }

}

const LoadQuestions = (props) => {
    let { toggleReloadData, data, userid, global } = props
    const [ sending, setSending ] = useState(false)
    let actualmodule=data.moduloactual
    let questions=data.questions;

    const submitAwnsers = () => {
        let awnsers=document.getElementsByClassName("fieldquestions")
        let state=this
        document.getElementById('loadingspacequestions').scrollIntoView({ behavior: 'smooth' })
        setSending(true)
        let someempty=0
        let wait=1
        let objecttosend=new FormData()
        objecttosend.append('itemidalumno', actualmodule.id)
        let first = null
        for(var i = 0; i < awnsers.length; i++){
            let id=awnsers[i].getAttribute("data-id")
            let savedid=awnsers[i].getAttribute("data-savedid")
            let text
            let datatype=awnsers[i].getAttribute("data-type")
            let awnserthis
            if(datatype == 1){
                awnserthis=awnsers[i].value
            }else{
                let itemsoptions=document.getElementsByClassName("checkfield"+id)
                let arrayoptions=[]
                let atleastone=0;
                let count=0;
                for(var a = 0; a < itemsoptions.length; a++)
                {
                    count++
                    let val
                    if(itemsoptions[a].checked == true){
                        val=true
                        atleastone++
                    }else{
                        val=false
                    }
                    arrayoptions[count]={'itemalumnoid':actualmodule.id,'questionid':id,'checked' : val, 'value' : itemsoptions[a].value}
                }
                if(atleastone == 0){
                    awnserthis=""
                }else{
                    awnserthis=arrayoptions
                }

            }

            if(awnserthis == ""){
                if(first == null){
                    first=id
                }
                text='<span class="alert d-inline-block alert-sm p-1 mb-2 small alert-warning mt-3">Este campo no puede estar vacío.</span><br/>'
                someempty++
            }else{
                text=''

            }
            document.getElementById('validationspace'+id).innerHTML=text
        }

        if(someempty == 0){
            let params=null
            let url = global.domain+'api/api/sendingAwnserst/'+params+'/'+global.apicode;

            fetch(url,{
                method: "post",
                body: objecttosend
            })
                .then(response => response.text())
                .then(datad => {
                    toggleReloadData()
                })
                .catch(error => {
                    console.error(error)
                })
        }else{
            setSending(false)
            document
                .getElementById('questionsfield'+first)
                .scrollIntoView({ behavior: 'smooth' })
        }
    }


    const savingcheck = (value, userid) => {
        setSending(true)
        let params={userid: userid}
        params=btoa(JSON.stringify(params))
        let url = global.domain+'api/v1/sendingAutosaveCheckdd/'+global.apicode+'/'+params;

        let questionid=value.questionid
        loadingsavingornot(questionid)
        let data = new FormData();
        data.append( "json", JSON.stringify(value));
        fetch(url,{
            method: "post",
            body: data
        })
            .then(response => response.text())
            .then(datad => {
                datad=JSON.parse(datad)
                datad=datad.result
                if(datad.new.question.id !== undefined) {
                    setSending(false)
                    let questionidsaved = datad.new.question.id
                    document.getElementById('fieldquestions' + questionid).setAttribute('data-savedid', questionidsaved)
                    showissavedornot(questionid,1)
                }else{
                    showissavedornot(questionid,0)
                }
            })
    }

    const showissavedornot = (questionid,yes) => {
        let name='validationspace'+questionid
        if(yes === 1){
            document.getElementById(name).innerHTML = '<i class="fas fa-check-circle text-success mr-2"></i>'
        }else{
            document.getElementById(name).innerHTML = '<i class="fas fa-ban text-danger mr-2"></i>'

        }
        setTimeout(()=>document.getElementById(name).innerHTML = "",500)
    }

    const loadingsavingornot = (questionid) => {
        let name='validationspace'+questionid
        document.getElementById(name).innerHTML = '<i class="fas fa-spinner fa-spin text-secondary mr-2"></i>'
    }

    const saving = (questionid, value, type, item=null, userid) => {
        setSending(true)
        loadingsavingornot(questionid)
        let current=actualmodule
        let itemalumnoid=current.id
        let objecttosend={itemalumnoid:itemalumnoid, value:value, questionid:questionid,type:type}
        if(type == 1){
            let spacefield=document.getElementById('fieldquestions'+questionid)
            if(spacefield){
                spacefield.classList.remove("bg-white")
                spacefield.classList.add("bg-light")
            }
        }else{
            objecttosend['checked']=item.checked
        }

        let params={userid: userid}
        params=btoa(JSON.stringify(params))
        let url = global.domain+'api/v1/sendingAutosave2/'+global.apicode+'/'+params;
        var data = Object.keys(objecttosend).map(function(k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(objecttosend[k])
        }).join('&')
        fetch(url,{
            method: "post",
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: data
        })
            .then(response => response.json())
            .then(datad => {
                if(datad.new.question.id !== undefined){
                    let questionidsaved=datad.new.question.id
                    document.getElementById('fieldquestions'+questionid).setAttribute('data-savedid',questionidsaved)
                    showissavedornot(questionid,1)
                }else{
                    showissavedornot(questionid,0)
                }
                if(datad.code == 2){
                    window.location.href = "https://cursos.birmingham-institute.com/";
                }
                setSending(false)
            })
    }

    const autoSave = (event, userid) =>{
        let questionid=event.target.getAttribute('data-id')
        let type=event.target.getAttribute('data-type')
        let value=event.target.value
        let itemalumnoid=event.target.getAttribute('data-itemalumnoid')

        if(type == 1){
            saving(questionid, value, type, event.target, userid)
        }else{
            let idName='checkfield'+questionid
            let checksvalues=document.getElementsByClassName(idName)
            let groupform={
                questionid:questionid,
                itemalumnoid:itemalumnoid,
                options:[]}
            let counter=0;
            for(let i=0; i < checksvalues.length; i++) {
                counter++
                let checkedval=checksvalues[i].checked
                if(checkedval == true){
                    checkedval=1
                }else{
                    checkedval=0
                }
                groupform.options.push({
                    questionid: checksvalues[i].getAttribute('data-id'),
                    value: checksvalues[i].value,
                    itemalumnoid: checksvalues[i].getAttribute('data-itemalumnoid'),
                    checked: checkedval,
                })
                if(counter == checksvalues.length){
                }
            }
            savingcheck(groupform, userid)
        }
    }

    let corregidopor
    if(actualmodule?.cursos_items_alumnos_correcciones_past[0]?.cursos_profesore?.nombre){
        corregidopor=<strong className={'ml-2'}>{actualmodule.cursos_items_alumnos_correcciones_past[0].cursos_profesore.nombre}</strong>
    }

    

    return <div className={'row no-gutters'}>
        <div className="col-12">
            <div id={'loadingspacequestions'}></div>
            {actualmodule.comments && corregidopor && <div className={'alert alert-secondary'}>{actualmodule.comments} - {corregidopor}</div>}

            {questions.map((item) => {
                const stylesHeading = item.explicacion === '1' ? {
                    paddingLeft: 27,
                    display: 'inline-block',
                    marginLeft: -24,
                    paddingRight: 46,
                } : {}

                const className = item.explicacion === '1' ? 'py-1 bg-success-dark text-bold text-white rightTriangle' : 'text-bold'

                return <div className={'mt-2'} id={'questionsfield'+item.id} key={'questionsitem'+item.id}>
                    {item.img && <img className={'mb-3'} src={global.domain+'/'+item.img}/>}
                    {item.audio && <audio controls><source src={global.domain+'/'+item.audio} type="audio/mpeg" /></audio>}
                    <span id={'validationspace'+item.id}></span>
                    <div className={className} style={stylesHeading} dangerouslySetInnerHTML={{__html:item.value}}></div>
                    <div className={'mt-3 mb-3'}>
                        <Fieldvalue autoSave={(e)=>autoSave(e, userid)} item={item} {...props}/>
                    </div>
                    <hr/>
                </div>
            })}
            <div>{actualmodule.grade !== "100" ? <>
                {!sending ? <PaginationItems processingAutoSave={sending} submitAwnsers={submitAwnsers} /> : <Loader />}
            </> :<div></div>}

            </div>
        </div>
    </div>

}

const LoadAsesoria = (props) => {
    let{itemid,data,cursoid}=props

    let render = data
    let sesionesdisponibles=render.sesionesdisponibles
    let onlineprofesores=render.onlineprofesores

    if(sesionesdisponibles >= 1){
        if(onlineprofesores){
            return <div>
                <a href={`/consulta/${cursoid}/${itemid}`}
                    className={'btn btn-lg btn-success text-white'}
                >Comenzar práctica oral o asesoría</a>
            </div>
        }else{
            return <div className={'alert alert-warning'}>
                <p>En estos momentos no hay profesores conectados que puedan atenderle.</p>
            </div>
        }
    }else{
        return <div className={'alert alert-warning'}>
            <p>No tiene sesiones disponibles para tomar esta práctica.
                <a className="alert-link" href="/comprar/1">Comprar sesiones</a>
            </p>
        </div>
    }
}

const LoadContentCorrect = (props) => {
    let { typeid } = props
    if(typeid === 8){
        return <LoadAsesoria {...props}/>
    }else if(typeid === 4){
        return <LoadQuestions {...props}/>
    }else if(typeid === 5){
        return <LoadAssigments {...props}/>
    }else if(typeid === 9){
        return <LoadAutoCorrectTypeContent {...props}/>
    }
}

const LoadAutoCorrectTypeContent = (props) => {
    let {itemid, id, global, user} = props;

   const [ cursoItem, setCursoItem ] = useState(false)
    let params={ itemid: itemid }

    useEffect( () =>{
        let url = global.domain+'api/v1/getCursoItem/'+global.apicode;
        fetching(url,params,()=>{}).then( (re) => {
            setCursoItem(re)
           ;
        });
    },[])
    console.log(user)
    return <div>
        { cursoItem && <div id={'animated-space-v3'} data-id={id}>
            <div dangerouslySetInnerHTML={
                {
                    __html:'<iframe id="animation-iframe" width="100%" border="0" style="min-height:600px;" src="'+global.domain+cursoItem.data.url_animate+'?itemid='+id+'&nickname='+user.nickname+'&porqueaprender='+user.porqueaprender+'"></iframe>'}}></div>
        </div> }
    </div>
}

const Exercisestructure = props => {
    let { data, markedAsDone, userid, modal, loadData, cursoid, showCorreciones } = props
    const callback = (modal) => {
        loadData();
        setTimeout(() => modal.toggleModal(), 5000); 
    }
    const showFeedbackFrom = (correccion_id, profesor_id, curso_id) => {
        modal.setModalContent({title: "", content: <FeedbackForm type={0} eventable_id={correccion_id} profesor_id={profesor_id} curso_id={curso_id} callback={callback}/> }) 
        modal.toggleModal() 
    }

    let moduloactual =data.moduloactual
    let currentcorrection=moduloactual.cursos_items_alumnos_correccione
    let correcciones

    if(currentcorrection !== null ){
        correcciones=moduloactual.cursos_items_alumnos_correcciones_all.filter(item => currentcorrection.id !== item.id)
    }else{
        correcciones=moduloactual.cursos_items_alumnos_correcciones_all
    }

    let hecho = moduloactual.hecho
    let corrected = moduloactual.corrected
    let type=moduloactual.cursos_item.typeid
    let isacorrecttype
    let correcttype=[8,5,4,9]
    isacorrecttype=correcttype.includes(type);
    if(!isacorrecttype && hecho){
        // Si no es corregible y esta hecho
        return <div className={'alert alert-success mt-3'}>Este item ha sido completado.</div>
    }else if(isacorrecttype && hecho && !corrected){
        // Si es corregible y esta hecho pero no esta corregido aun
        return <div className={'alert alert-info'}>Este item será corregido pronto. Una vez esté el resultado se librerará presentando el estado del mismo.</div>
    }else if(isacorrecttype && !hecho || (hecho && corrected)){
        // Si es corregible y esta hecho pero esta corregido o Si es corregible, pero no esta hecho
        return <div className={'row'}>
            <div className={'col-12 col-md'}>
                {hecho && moduloactual.grade === "100" ? <div className={'alert alert-success'}>Este item ha sido completado.</div> :null}
                <LoadContentCorrect 
                    cursoid={cursoid}
                    userid={userid}
                    id={moduloactual.id} 
                    itemid={moduloactual.cursos_item.id} 
                    typeid={type} 
                    {...props} />
            </div>
            {showCorreciones && correcciones.length > 0 ?
                <div className={'col-12 col-md-3'}>
                    <div className={'text-uppercase border-bottom'}>Historial de correcciones</div>
                    {correcciones.map(item => {
                        return <div key={'correcciones'+item.id}>
                            {item.cursos_profesore !== null && <small><span>Corregido por {item.cursos_profesore.nombre}</span></small>}
                            {item.cursos_profesores_feedback === null 
                                ? 
                                    <div>
                                        <button onClick={ () => showFeedbackFrom(item.id, item.cursos_profesore.id, moduloactual.cursos_item.cursos_modulo.cursoid) } className={'btn btn-primary'}><Star color={'#ffffff'} height={'20'} />  Evalúa el instructor</button>
                                    </div> 
                                : 
                                    <RenderFeedbackRate valoracion={item.cursos_profesores_feedback.valoracion} />
                                }
                            {item.approved == 1 && <span className={'badge badge-success'}>Aprobado</span>}
                            <span className={'badge alert-info mt-2'}>Enviado <DateFormatted value={item.date} /></span>
                            <span className={'badge alert-success mt-2'}>Corregido el <DateFormatted value={item.date_corrected} /></span>
                            { item.comentario !== null ? <div className={'alert px-2 py-1 alert-warning mt-3'}>Comentario: <strong>{item.comentario}</strong></div> :null}
                        </div>
                    })}
                </div>
                :null}
        </div>
    }

    let correct
    let all = ""
    let message=''

    if(moduloactual.cursos_items_alumnos_correccione !== undefined && moduloactual.cursos_items_alumnos_correccione !== null && moduloactual.cursos_items_alumnos_correccione.corrected === 0){
        message='Estamos revisando esta asignación.'
    }
    correct=correcttype.includes(type);
    if(moduloactual.hecho === true){
        let actualmodule=moduloactual
        if(!correct){
            message='Este item ha sido completado.'
        }
        if(correct){
            if(moduloactual.corrected == 1 ){
                if(moduloactual.cursos_items_alumnos_correccione !== undefined && moduloactual.cursos_items_alumnos_correccione !== null && moduloactual.cursos_items_alumnos_correccione.approved == 1){
                    message='Este item ha sido completado.'
                }

                if(moduloactual.cursos_items_alumnos_correccione !== undefined && moduloactual.cursos_items_alumnos_correccione !== null && moduloactual.cursos_items_alumnos_correccione.corrected === 1 && actualmodule.comments !== null){
                    message+=' Comentario del profesor: <strong>'+actualmodule.comments+'</strong>'
                }
                let questions=moduloactual.questions;
                let items_alumnos_questions = data.item_alumnos_records_questions
                let fieldvalue
                {questions.map((item)=>{
                    let pastvalue
                    const cursos_items_alumnos_questions = items_alumnos_questions.find(iaq => iaq.questionid == item.id);
                    if(item.campodetexto){
                        let classbginput=''
                        if(cursos_items_alumnos_questions !== undefined && cursos_items_alumnos_questions.length > 0){
                            cursos_items_alumnos_questions.map((currentitm)=>{
                                if(currentitm.questionid == item.id){
                                    if(currentitm.cursos_items_alumnos_awnser.length > 0){
                                        pastvalue=currentitm.cursos_items_alumnos_awnser[0].valuetext
                                    }
                                }
                            })
                        }
                        fieldvalue="<div><div class='questionsfield'><textarea data-type='1' name='field'"+item.id+" data-id='"+item.id+"' required='required' id='fieldquestions"+item.id+"' class='fieldquestions form-control mb-3 "+classbginput+"' readOnly={true} disabled='disabled' >"+pastvalue+"</textarea></div></div>"
                    }else if(!item.explicacion) {
                        let pastvalue = []
                        let pastquestions=null
                        if(cursos_items_alumnos_questions.length > 0){
                            var questionsawnsercurrent=cursos_items_alumnos_questions
                            questionsawnsercurrent.map((currentitm)=>{
                                if(currentitm.questionid == item.id){
                                    if(currentitm.cursos_items_alumnos_awnser.length > 0){
                                        let optionsselected=currentitm.cursos_items_alumnos_awnser
                                        let counting=0
                                        optionsselected.map((optionsel)=>{
                                            if(optionsel.value !== null){
                                                pastvalue[counting]=optionsel.value
                                                counting++;
                                            }
                                        })

                                    }
                                }
                            })
                        }
                        fieldvalue="<div><div data-type='2' data-id='"+item.id+"' class='questionsfield fieldquestions'>"
                        {
                            item.options.map((itemop)=>{
                                let chekedvalue=false
                                if(pastvalue.length > 0){
                                    if(pastvalue.includes(itemop.id)){
                                        chekedvalue=true
                                    }
                                }
                                fieldvalue+="<div class='d-inline mr-3'><label><input type='checkbox' data-id='item.id'";
                                if(chekedvalue){
                                    fieldvalue+="checked='"+chekedvalue+"'";
                                }
                                fieldvalue+="value='"+itemop.id+"' onclick='return false' class='checkfield"+item.id+"' name='field"+item.id+"' /> "+itemop.awnser+"</label></div>"

                            })
                        }
                        fieldvalue+="</div></div>"
                    }else if(item.explicacion){
                        fieldvalue=''
                        all+=''
                    }
                    all+="<div id='questionsfield"+item.id+"' >"
                    if(item.img !== null){
                        all+="<img class='mb-3' src='"+this.state.domain+"/"+item.img+"' />"
                    }
                    all+='<span class="text-bold" style="font-size: 140%">'+item.value+'</span><div class="mt-3 mb-4">'+fieldvalue+'</div></div>'

                })}

            }
        }

        if(moduloactual.nextitem !== null){
            return(
                <div className={'mt-3'}>
                    <div className={'d-flex w-100'}>
                        {message === null || message === "" ? null : <div className={'alert alert-success py-2 flex-fill'}>
                            <p className={'m-0'} dangerouslySetInnerHTML={{__html:message}}></p>
                        </div>}
                        <span className={'flex-fill  ml-2'}>
                                <button className={'btn btn-light'} onClick={()=>this.changeContent(data.nextitem)} data-id={data.id}>Siguiente</button>
                            </span>

                    </div>

                    <div className={'mb-5'} dangerouslySetInnerHTML={{__html:all}}></div>
                </div>
            )
        }else{
            return<div className={' '}>
                {message == null ? null :
                    <div className={'alert alert-info'}>
                        <p className={'m-0'}>{message}</p>
                    </div>}
                <div dangerouslySetInnerHTML={{__html:all}}></div>
            </div>

        }
    }else{
        if(correct && type !== 9){
            return  <LoadContentCorrect id={moduloactual.id} itemid={moduloactual.cursos_item.id} typeid={type} {...props} />
        } else if(correct && [9, 10].includes(type)){
            return <LoadAutoCorrectTypeContent id={moduloactual.id} itemid={moduloactual.cursos_item.id} typeid={type} {...props} />
        } else {
            return <button onClick={()=>{markedAsDone(moduloactual.id)}} className={'btn btn-info mt-3'} data-id={moduloactual.id}>Marcar como terminado</button>
        }
    }
}


const LoadAssigments = props => {
    let {data, global, toggleReloadData, userid } = props
    const [sending,setSending]=useState(false)
    let actualmodule=data.moduloactual
    let assigments=data.assigments;
    let hecho = actualmodule.hecho

    let styles
    if(sending){
        styles={display:'block',backgroundColor:'rgba(255,255,255,.5)'}
    }else{
        styles={display:'none',backgroundColor:'rgba(255,255,255,.5)'}
    }

    const submitAssigments = (userid) =>{
        setSending(true)
        let data = new FormData();
        let itemid = actualmodule.cursos_item.id
        let itemalumnoid = actualmodule.id
        // file selected by the user
        // in case of multiple files append each of them
        let fieldsall=document.getElementsByClassName('fieldstouploas')
        let allfiles=0;
        let files=[]
        for (let i = 0; i < fieldsall.length; i++) {
            let datainfo=fieldsall[i].getAttribute('data-requirementid')
            let assigmentinfo=fieldsall[i].getAttribute('data-assigmentid')
            let classnamemessage='spacetoshowre'+datainfo
            let value=''

            if(fieldsall[i].files.length == 0){
                value="<div class='alert alert-info'>Este campo es requerido.</div>"
                allfiles++
            }else{
                let filename = fieldsall[i].files[0].name
                data.append('item['+assigmentinfo+']['+datainfo+'][file]', fieldsall[i].files[0]);
                data.append('item['+assigmentinfo+']['+datainfo+'][requirementid]',datainfo);
                data.append('item['+assigmentinfo+']['+datainfo+'][asignacionid]',assigmentinfo);
            }
            document.getElementById(classnamemessage).innerHTML=value
        }

        if(allfiles == 0){
            document.getElementById('spaceAssigments').innerHTML='<i class="fas fa-spinner fa-spin"></i>'
            let urld = global.domain+'/api/processasignacionesjsond/'+userid+'/'+itemid+'/'+itemalumnoid
            fetch(urld, {
                method: 'POST',
                body: data
            })
                .then(response => response.text())
                .then(data => {
                    data=JSON.parse(data)
                    toggleReloadData()
                })
                .catch(error => {
                    console.error(error)
                })

        }
    }

    return <div className={'row'}>
        <div className={'position-relative col-12 col-md-12'} id={'spaceAssigments'}>
            <div id={'loadingspacequestions'} className={'loadingspace text-center position-absolute h-100 w-100 p-4'} style={styles}>
                <Loader />
            </div>
            {!hecho ?
                <>
                    {assigments.map(item=>{
                        return <div id={'questionsfield'+item.id} key={'questionsitem'+item.id}>
                            <span id={'validationspace'+item.id}></span>
                            <span dangerouslySetInnerHTML={{__html:item.intrusccionestranslated}}></span>
                            <div key={'assigment'+item.id} data-type={2} data-id={item.id} className={'questionsfield fieldquestions'}>
                                <strong>{item.titulo}</strong>
                                {item.cursos_item_asignaciones_requerimiento.map((itemop)=>{
                                    let formatos=itemop.formatos
                                    formatos=formatos.replace(".", "")
                                    return <div key={'assigmentreq'+item.id+itemop.id}><span>{itemop.titulo}</span><div className={'spaceuploader spacetoshowre'+itemop.id}><p>Formatos permitidos: {formatos}</p>
                                        <input type="file" data-requirementid={itemop.id} data-assigmentid={item.id} name={"asignacion["+item.id+"]["+itemop.id+"]"} className={"fieldstouploas form-control filerequired"+itemop.id+" mb-3"} accept={formatos} />
                                        <div id={'spacetoshowre'+itemop.id}></div>
                                    </div></div>
                                })}
                            </div>
                        </div>
                    })}
                    <div> {!sending ? <button onClick={() => submitAssigments(userid)} className={'btn btn-info mt-3'} type={'button'}>Enviar</button> : null}</div>
                </>
                : null}

        </div>
    </div>
}

const Content = props => {
    let { data, global } = props
    let moduloactual =data.moduloactual
    if (moduloactual ) {
        let contenitem = moduloactual?.cursos_item
        let imgvalue=''
        let audio=''
        let html=''
        let video=''
        let pdf=''
        if(contenitem?.img !== null){
            imgvalue='<img class="mb-3" src="'+global.domain+'/'+contenitem.img+'" />'
        }

        if(contenitem?.audiourl !== null && contenitem?.audiourl !== ""){
            audio=contenitem.audiourl
            audio='<audio width="100%" controls><source id="src_mp3" type="audio/mp3" src="'+global.domain+'/'+audio+'"/></audio>'
        }

        if(contenitem?.videourl !== null && contenitem?.videourl !== ""){
            video=contenitem.videourl
            video='<iframe src="https://player.vimeo.com/video/'+video+'" width="100%" height="480" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>'
        }

        if(contenitem?.pdfurl !== null && contenitem?.pdfurl !== ""){
            pdf=global.domain+'/'+contenitem.pdfurl
            pdf='<iframe width="100%" height="500" frameborder="0" src="https://docs.google.com/viewer?url='+pdf+'&embedded=true"></iframe>'
        }

        if(contenitem?.html !== null && contenitem?.html !== ""){
            html=contenitem.html
        }

        if(contenitem?.descripcion =='<p>&nbsp;</p>' || contenitem?.descripcion =='&nbsp;'){
            contenitem.descripcion="";
        }

        return <div>
            <Title value={moduloactual?.id+". "+contenitem?.titulo} />
            <>
                <div id={'loadingspacemenuautosave'}></div>
                <div dangerouslySetInnerHTML={{__html : contenitem.descripcion}}></div>
                <div dangerouslySetInnerHTML={{__html : imgvalue}}></div>
                <div dangerouslySetInnerHTML={{__html : audio}}></div>
                <div dangerouslySetInnerHTML={{__html : video}}></div>
                <div dangerouslySetInnerHTML={{__html : html}}></div>
                <div dangerouslySetInnerHTML={{__html : pdf}}></div>
            </>
            <Exercisestructure {...props}/>
        </div>
    } else {
        return null
    }

}

const AboutModule = (props) => {
    let {data,changeModule} = props
    let moduloactualda=data.moduloactual
    let moduloactual=moduloactualda.cursos_item.cursos_modulo
    let htmlString= moduloactual.objetivos;
    let stripedHtml = htmlString.replace(/<[^>]+>/g, '');
    if(stripedHtml == "&nbsp;"){
        stripedHtml=""
    }

    return <div className={'mt-3 mr-3 '}>
        <p className={'text-uppercase small mb-1'}>Éste módulo</p>
        <Title value={moduloactual.titulo}/>
        <div className={'row mb-3'}>
            <div className={'col-12'}>
                <span className={'badge badge-success'}>{moduloactual.cursos_curso.nombre}</span>
                {data.allfinished && data.nextmoduleitem !== null ? <span className={'badge badge-primary ml-2'}>Completado</span>:null }
            </div>

        </div>
        <div className={'text-justify small'}>{stripedHtml}</div>
        {data.allfinished && data.nextmoduleitem !== null ? <div>
            <a className={'btn btn-info my-3 text-white btn-block'} onClick={()=>changeModule(data.nextmoduleitem)}>Siguiente módulo</a>
        </div> :null}
    </div>
}


const getIconByType = (id,type,aprobado=null,pending=null) =>{
    let icon
    let data
    let status
    if (type == 1){
        if(data !== undefined && data !== null){
            data=data.items_text
        }
        icon=<span className={'mr-1'} id={"containeritemicon"+id}><span id={"linkitemicon"+id}><DocumentTextOutline height="25px" width="25px" /></span></span>
    }

    if (type == 2){
        if(data !== undefined && data !== null){
            data=data.items_video
        }
        icon=<span className={'mr-1'} id={"containeritemicon"+id}><span id={"linkitemicon"+id}><VideocamOutline height="25px" width="25px" /></span></span>
    }

    if (type == 3){
        if(data !== undefined && data !== null){
            data=data.items_audio
        }
        icon=<span className={'mr-1'} id={"containeritemicon"+id}><span id={"linkitemicon"+id}><PlayCircleOutline height="25px" width="25px" /></span></span>
    }

    if (type == 4){
        if(data !== undefined && data !== null){
            data=data.items_preguntas
        }
        icon=<span className={'mr-1'} id={"containeritemicon"+id}><span id={"linkitemicon"+id}><ListOutline height="25px" width="25px" /></span></span>
    }

    if (type == 5){
        if(data !== undefined && data !== null){
            data=data.items_asignaciones
        }
        icon=<span className={'mr-1'} id={"containeritemicon"+id}><span id={"linkitemicon"+id}><CloudUploadOutline height="25px" width="25px" /></span></span>
    }

    if (type == 6){
        if(data !== undefined && data !== null){
            data=data.items_pdf
        }
        icon=<span className={'mr-1'} id={"containeritemicon"+id}><span id={"linkitemicon"+id}><AttachOutline height="25px" width="25px" /></span></span>
    }

    if (type == 7){
        if(data !== undefined && data !== null){
            data=data.items_html
        }
        icon=<span className={'mr-1'} id={"containeritemicon"+id}><span id={"linkitemicon"+id}><CodeSlashOutline height="25px" width="25px" /></span></span>
    }

    if (type == 8){
        if(data !== undefined && data !== null){
            data=data.items_oral
        }
        icon=<span className={'mr-1'} id={"containeritemicon"+id}><span id={"linkitemicon"+id}><PeopleCircleOutline height="25px" width="25px" /></span></span>
    }

    if(aprobado !== null && aprobado == 1){
        status=<span className={'mr-1'} id={"containeritemicon"+id}><span id={"linkitemicon"+id}><CheckmarkCircle color={'green'} height="20px" width="20px" /></span></span>
    }

    if(pending !== null && pending == 1){
        status=<span className={'mr-1'} id={"containeritemicon"+id}><span id={"linkitemicon"+id}><TimerOutline height="20px" width="20px" color={'red'} /></span></span>
    }

    return {icon:icon,data:data,status:status}
}

const Leftside = (props) => {
    const { data, changeContent }=props

    let actualitemid=data.moduloactual?.itemid
    let items = data.items

    return <div className={'navigation border-right h-100 mr-4 pr-4'}>
        <ul className={'nav sideitems flex-column'}>
            {
                items?.map((item)=>{
                    let res = getIconByType(item.id,item.cursos_items_type.id,item.aprobado,item.pending,null)
                    let icon = res.icon
                    let statusicon = res.status
                    return <li key={'itemsicon'+item.id} className={actualitemid === item.id ? 'nav-item active' :'nav-item'}><button className={'nav-link linkitems w-100 text-left py-2 link'} id={'linkitem'+item.id} onClick={() => changeContent(item.id)} >{statusicon}{icon}<span className={'mt-1 pt-1'}>{item.titulo}</span><span className={'ml-1'} id={'loadingspacemenu'+item.id}></span></button></li>
                })
            }
        </ul>
        <AboutModule {...props}/>
    </div>
}

const Breadcrumb = ({ data }) => {
    let moduloactual=data.moduloactual
    return <nav aria-label={'brearcrumb'}>
        <ol className={'breadcrumb pb-2 mb-0 bg-white border-bottom'}>
            <li className={'breadcrumb-item'}><a href="/cursos-activos" className={'link'}>Cursos</a></li>
            { moduloactual && (
                <>
                    <li className={'breadcrumb-item'}><a href={`/resumen/${moduloactual.cursos_item?.cursos_modulo?.cursoid}`} className={'link'}>{moduloactual.cursos_item.cursos_modulo.cursos_curso.nombre}</a></li>
                    <li className={'breadcrumb-item active'}>{moduloactual.cursos_item.cursos_modulo.titulo}</li>
                </>
            )}
        </ol>
    </nav>
}

export const Tracking = ( props ) => {
    const { id } = useParams();
    const [ page, setPage ] = useState(1)
    const [ data, setData ] = useState(null)
    const [ courseState, setCourseState ] = useState(null);
    const [ reloadData,setReloadData ] = useState(false)
    const [ showside, setShowside ] = useState(true);
    const [ showCorreciones, setShowCorrecciones ] = useState(true)
    const { user, modal, global } = useContext(GeneralContext)
    const cursoid = id;
    const [ ,setAlert ]= useAlert();
    const userid = user.user.user.id_alumno;
    
    const changeContent = (itemid) =>{
        markasActual(itemid)
    }

    const markedAsDone = (itemalumnoid) => {
        let moduloactual=data.moduloactual
        let url=global.domain+'api/markasdoneapi/'+cursoid+'/'+itemalumnoid+'/'+global.apicode;
        let nexitem=null
        if(moduloactual.nextitem !== undefined){
            nexitem=moduloactual.nextitem
        }
        fetch(url)
            .then(()=>{
                if(nexitem !== null){
                    changeContent(nexitem)
                }else{
                    toggleReloadData()
                }
            })
    }

    const markasActual = (itemid) => {
        setPage(1)
        setData(null)
        let params={
            userid,
            cursoid,
            itemid
        }
        params=btoa(JSON.stringify(params))

        let url=global.domain+'api/v1/markasactual2/'+global.apicode+'/'+params

        fetch(url).then(()=>{
            toggleReloadData()
        })
    }

    const toggleReloadData = () => {
        setReloadData(!reloadData)
    }

    const changeModule = (itemid) => {
        markasActual(itemid)
    }

    const loadData = () => {
        let expirationdate= Date.parse(courseState?.curso_alumno.expirate_date)
        let now = new Date()
        let today = Date.parse(dateformat(now, "isoDateTime"))
        if(expirationdate < today ){
            setAlert({alert:'El tiempo de expiración de este curso ha vencido',type:'alert-danger'})
            window.location.href = '/escritorio';
            return;
        }
        if(cursoid === undefined ){
            window.location.href ="/escritorio"
        }
        if(cursoid){
            post(
                global.domain+"v3-api/v3/detail_course2.json",
                { 
                    cursoid,
                    userid,
                    page
                },
                setData
            )
        }
    }

    useEffect(
        () => {
            post(
                global.domain+"v3-api/v3/course_state.json",
                { 
                    cursoid,
                    userid
                },
                setCourseState
            )

            sendTrack(userid, window.location.pathname, window.location.hostname, global);
            window.nombre = user.user.user.Nombre;
            window.nickname = user.user.user.nickname;
        },
        []
    )

    useEffect(
        () => loadData(),
        [courseState, reloadData]
    )

    const generalClassNameForToggle = 'btn text-center link rounded p-2';
    const classNameForToggle = showside ? `${generalClassNameForToggle} bg-secondary text-white` : `${generalClassNameForToggle}  bg-light`;

    return <><div className={'row no-gutters h-100 '}>
        {data !== null ? <>
            <div className={'col-12'}><Breadcrumb data={data} {...props} /></div>
            { showside && 
                <div className={'col-6 col-md-5 col-lg-4'}>
                    <Leftside changeModule={changeModule} changeContent={changeContent} data={data} {...props}/>
                </div>
            }
            <div className={'col col-md h-100 '}>
                <div className="d-flex gap-2 my-3">
                    <button onClick={() => setShowside(!showside)} className={classNameForToggle} dangerouslySetInnerHTML={{__html:'<i class="fas fa-list"></i>'}}></button>
                    <button onClick={() => setShowCorrecciones(!showCorreciones)} className={classNameForToggle} dangerouslySetInnerHTML={{__html:'<i class="fas fa-clock"></i>'}}></button>
                </div>
                <div className={'py-3 px-0 w-100'}>
                    <Content 
                        user={user.user.user}
                        userid={userid}
                        markedAsDone={markedAsDone} 
                        toggleReloadData={toggleReloadData} 
                        data={data} 
                        reloadData={reloadData} 
                        setData={setData} 
                        modal={modal}
                        loadData={loadData}
                        global={global}
                        cursoid={cursoid}
                        showCorreciones={showCorreciones}
                        {...props}
                    />
                </div>
            </div>
        </> : <Loader/>}
    </div></>
}
